<template>
  <div class="sign" v-if="!isMobile">
    <div class="return flex align" @click="toReturn">
      <img src="@/assets/return.png" />
      <div>返回上一页</div>
    </div>
    <img src="@/assets/bg3.png" class="bg" />
    <div class="tit_img"><img :src="titImg" /></div>
    <!-- <div class="tit_img"><img src="@/assets/tit2.png" /></div> -->
    <div class="publish">
      <!--canvas截取流-->
      <div class="video-div box">
        <canvas ref="canvas" width="881" height="641"></canvas>
        <div class="line"></div>
        <div class="bottom"></div>
        <video ref="video" autoplay></video>
        <div class="times" v-if="!isShow">{{ count }}</div>
        <img src="@/assets/kuang.png" class="kuang" />
      </div>
      <div class="tips">请将脸部正对摄像头</div>
      <!-- <button @click="photograph">拍照</button> -->
    </div>
    <!-- 拍摄须知 -->
    <div class="known">
      <div class="tit">拍摄须知</div>
      <div class="flex between">
        <div class="known-item">
          <img src="@/assets/h1.png" class="head" />
          <div class="flex"><img src="@/assets/del.png" />请不要戴眼镜</div>
        </div>
        <div class="known-item">
          <img src="@/assets/h2.png" class="head" />
          <div class="flex"><img src="@/assets/del.png" />请不要戴帽子</div>
        </div>
        <div class="known-item">
          <img src="@/assets/h3.png" class="head" />
          <div class="flex"><img src="@/assets/del.png" />请不要戴口罩</div>
        </div>
      </div>
    </div>
    <!-- 打卡成功 -->
    <van-overlay :show="show">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="cutTime">{{ count2 }}</div>
          <div class="show-info">
            <img src="@/assets/bg4.png" class="bg4" />
            <div class="tit">签到打卡</div>
            <img src="@/assets/icon1.png" class="icon1" />
            <div class="info1">{{ userInfo.name }}签到打卡成功</div>
            <div class="info2">{{ userInfo.clockin_time }}</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="showLoad">
      <div class="wrapper">
        <van-loading
          type="spinner"
          vertical
          size="80"
          text-size="40"
          color="#E26A26"
          text-color="#E26A26"
          >加载中...</van-loading
        >
      </div>
    </van-overlay>
    <van-overlay :show="faceLoad">
      <div class="wrapper">
        <van-loading
          type="spinner"
          vertical
          size="80"
          text-size="40"
          color="#E26A26"
          text-color="#E26A26"
          >打卡中...</van-loading
        >
      </div>
    </van-overlay>
    <div class="showSel" v-if="showSel">
      <div class="wrapper">
        <div class="wrapper-sel">
          <div class="wrapper-sel-tit">请选择人员打卡</div>
          <div class="flex align sel-list">
            <div
              class="sel-item"
              @click="toSel(item.uid)"
              :class="uid == item.uid ? 'sel-item-act' : ''"
              v-for="(item, index) in listSelect"
              :key="index"
            >
              <img :src="item.id_photo" />
              <div>{{ item.name }}</div>
            </div>
            <!-- <div class="sel-item">
              <img src="@/assets/h1.png" />
              <div>姓名</div>
            </div>
            <div class="sel-item">
              <img src="@/assets/h1.png" />
              <div>姓名</div>
            </div> -->
          </div>
          <div class="sel-btn" @click="toSign2()">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Icon, Toast, Overlay, Loading, Dialog } from "vant";
import {
  upLoadImage,
  clockinSubmit,
  getPeopleClockinData,
  positionDetail,
  clockinCommit,
} from "@/api/apiData";
export default {
  components: {
    [Icon.name]: Icon,
    [Toast.name]: Toast,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      showSel: false,
      showLoad: true, //加载弹窗
      isShow: true,
      count: 10, //倒计时 计数器
      show: false,
      count2: 3, //倒计时 计数器
      times: null,
      times2: null,
      faceLoad: false,
      userInfo: "",
      titImg: "",
      isMobile: false,
      listSelect: [],
      uid: "",
    };
  },
  mounted() {
    this.getPath();
    getPeopleClockinData().then((res) => {
      if (res.code == 1) {
        this.count = res.data.countdown_time;
        this.openPhoto();
      } else {
        Toast.fail(res.code);
      }
    });
  },
  methods: {
    // 判断是否是手机端，如果是，返回true
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    getPath() {
      positionDetail({ position_id: 3 }).then((res) => {
        if (res.code == 1) {
          this.titImg = res.data.path;
        } else {
          Toast.fail(res.msg);
        }
      });
    },
    // 打开摄像头
    openPhoto() {
      // H5调用电脑摄像头API
      //navigator.mediaDevices.getUserMedia 调用后 会提示用户给予使用媒体输入的许可.返回一个 Promise 对象，
      //成功后会resolve回调一个 MediaStream 对象。
      //在H5设备上面调用摄像头也可以通过此种方式，如下的例子表示优先使用前置摄像头
      //{ audio: true, video: { facingMode: "user" } }前摄像头
      //{ audio: true, video: { facingMode: { exact: "environment" } } }强制使用后置摄像头
      //设置获取接近 1280x720 的相机分辨率
      //{ audio: true, video: { width: 1280, height: 720 } };
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: { width: 1280, height: 720 },
        })
        .then((success) => {
          // 摄像头开启成功
          this.$refs["video"].srcObject = success; //srcObject 是实时流
          // 实时拍照效果
          this.$refs["video"].play();
          this.$refs["video"].muted = true;
          console.log("摄像头开启成功");
          this.showLoad = false;
          this.cutTime();
          // this.photograph();
          // setTimeout(() => {
          //   this.photograph();
          // }, 1000);
        })
        .catch((error) => {
          //摄像头开启失败
          Toast.fail("摄像头开启失败");
          console.error("摄像头开启失败");
        });
    },
    // 拍照
    photograph() {
      let photoInfo = this.$refs["canvas"].getContext("2d");
      // 把当前内容渲染到canvas上
      photoInfo.drawImage(this.$refs["video"], 0, 0, 881, 641);
      //canvas图片 转base64格式、图片格式转换、图片质量压缩
      let imgBase64 = this.$refs["canvas"].toDataURL("image/jpeg", 0.7);
      let string = imgBase64.replace("data:image/jpeg;base64,", "");
      let strLgh = string.length;
      let fileLgh = parseInt(strLgh - (strLgh / 8) * 2); //
      let size = (fileLgh / 1024).toFixed(2); // 由字节转换为KB 判断大小
      this.faceLoad = true;
      let that = this;
      upLoadImage({ file: imgBase64 }).then((res) => {
        if (res.code == 1) {
          let image = res.data.path;
          clockinSubmit({ image: image }).then((res2) => {
            that.faceLoad = false;
            if (res2.code == 1) {
              if (res2.data.type == 2) {
                that.showSel = true;
                that.listSelect = res2.data.list;
              } else {
                that.cutTime2();
                that.userInfo = res2.data;
              }
            } else {
              Dialog.alert({
                width: 800,
                className: "newClass",
                title: "提示",
                message: res2.msg,
              }).then(() => {
                that.toReturn();
              });
            }
          });
        } else {
          Dialog.alert({
            width: 800,
            className: "newClass",
            title: "提示",
            message: res.msg,
          }).then(() => {
            that.toReturn();
            // on close
          });
          this.faceLoad = false;
        }
      });
    },
    toReturn() {
      this.$router.go(-1);
    },
    // 倒计时
    //点击事件
    cutTime() {
      this.isShow = false; //倒计时
      this.times = setInterval(() => {
        this.count--; //递减
        if (this.count <= 0) {
          // <=0 变成获取按钮
          this.isShow = true;
          this.photograph();
          clearInterval(this.times);
        }
      }, 1000); //1000毫秒后执行
    },
    //点击事件
    cutTime2() {
      this.show = true;
      this.faceLoad = false;
      this.times2 = setInterval(() => {
        this.count2--; //递减
        if (this.count2 <= 0) {
          // <=0 变成获取按钮
          clearInterval(this.times2);
          this.toReturn();
        }
      }, 1000); //1000毫秒后执行
    },
    toSel(u) {
      this.uid = u;
    },
    toSign2() {
      if (this.uid == "") {
        Dialog.alert({
          width: 800,
          className: "newClass",
          title: "提示",
          message: "请点击选择人员",
        }).then(() => {});
      } else {
        clockinCommit({ uid: this.uid }).then((res) => {
          if (res.code == 1) {
            this.showSel = false
            this.cutTime2();
            this.userInfo = res.data;
          } else {
            Dialog.alert({
              width: 800,
              className: "newClass",
              title: "提示",
              message: res.msg,
            }).then(() => {
              this.toReturn();
            });
          }
        });
      }
    },
  },
  //实例销毁之前清除定时器
  beforeDestroy() {
    if (this.times) {
      clearInterval(this.times);
    }
    if (this.times2) {
      clearInterval(this.times2);
    }
  },
};
</script>

<style lang="less" scoped>
.sign {
  .bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .publish {
    text-align: center;
    .video-div {
      margin: 0 auto;
      position: relative;
      width: 881px;
      height: 641px;
      overflow: hidden;
      .times {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 300px;
        width: 100%;
        height: 100%;
        color: #fc6207;
      }
      .line {
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100% - 2px);
        width: 100%;
        background: linear-gradient(
          180deg,
          rgba(0, 255, 51, 0) 43%,
          #fc6207 211%
        );
        border-bottom: 2px solid #fc6207;
        transform: translateY(-100%);
        animation: radar-beam 2s infinite;
        animation-timing-function: cubic-bezier(0.3, 0, 0.43, 0.7);
        animation-delay: 1.4s;
      }
      canvas {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        // z-index: -1;
      }

      video {
        width: 100%;
        height: 100%;
        //   height: 200px;
        object-fit: fill;
      }
      .kuang {
        width: 90%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 5%;
      }
    }
    .box:after,
    .box:before,
    .bottom:after,
    .bottom:before {
      content: "";
      display: block;
      position: absolute;
      width: 3vw;
      height: 3vw;
      border: 8px solid transparent;
    }

    .box:after,
    .box:before {
      top: 0;
      border-top-color: #fc6207;
    }
    .bottom:after,
    .bottom:before {
      bottom: 0;
      border-bottom-color: #fc6207;
    }
    .box:before,
    .bottom:before {
      left: 0;
      border-left-color: #fc6207;
    }
    .box:after,
    .bottom:after {
      right: 0;
      border-right-color: #fc6207;
    }

    .photo {
      text-align: center;
    }
    .tips {
      color: #333333;
      font-size: 40px;
      margin: 43px 0;
    }
  }
  .return {
    color: #ff690e;
    font-size: 40px;
    margin: 48px 51px;
    img {
      margin-right: 16px;
    }
  }
  .tit_img {
    text-align: center;
    margin: 88px 0 118px;
    img {
      width: 600px;
    }
  }
  .known {
    margin: 0 65px;
    .tit {
      font-size: 48px;
      margin: 48px 0;
    }
    .known-item {
      font-size: 32px;
      width: 288px;
      text-align: center;
      .head {
        width: 288px;
      }
      .flex {
        img {
          width: 29px;
          margin-right: 10px;
        }
      }
    }
  }
  @keyframes radar-beam {
    0% {
      transform: translateY(-100%);
    }

    100% {
      transform: translateY(0);
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .showSel {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.623);
    z-index: 20;
  }
  .wrapper-sel {
    background: white;
    width: 80%;
    font-size: 40px;
    padding: 30px;
    border-radius: 10px;
    .wrapper-sel-tit {
      font-size: 45px;
      margin-bottom: 40px;
    }
    .sel-list {
      flex-wrap: wrap;
      max-height: 1000px;
      overflow-y: auto;
      .sel-item {
        border: solid 4px #646363;
        width: 28%;
        margin-left: 3%;
        text-align: center;
        margin-bottom: 30px;
        padding: 20px 0;
        img {
          width: 100%;
          height:200px;
        }
      }
      .sel-item-act {
        border: solid 4px #eb5b08;
        color: #e94a34;
      }
    }
    .sel-btn {
      background: #e26a26;
      width: 50%;
      text-align: center;
      color: white;
      line-height: 2.5;
      margin: 50px auto 20px;
      border-radius: 50px;
    }
  }

  .block {
    font-size: 56px;
    .cutTime {
      width: 88px;
      height: 88px;
      border-radius: 50%;
      color: #e94a34;
      background: white;
      text-align: center;
      line-height: 88px;
      margin-left: auto;
    }
    .show-info {
      width: 720px;
      height: 720px;
      position: relative;
      text-align: center;
      .tit {
        color: #e26a26;
        font-size: 72px;
        padding: 68px 0 42px;
      }
      .bg4 {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: -1;
      }
      .icon1 {
        width: 379px;
      }
      .info1 {
        font-size: 40px;
        margin: 10px 0 24px;
      }
      .info2 {
        font-size: 40px;
        color: #999999;
      }
    }
    // width: 120px;
    // height: 120px;
    // background-color: #fff;
  }
}
</style>
