import { render, staticRenderFns } from "./sign.vue?vue&type=template&id=a3b1dfdc&scoped=true&"
import script from "./sign.vue?vue&type=script&lang=js&"
export * from "./sign.vue?vue&type=script&lang=js&"
import style0 from "./sign.vue?vue&type=style&index=0&id=a3b1dfdc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3b1dfdc",
  null
  
)

export default component.exports